// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.margins-checkbox {
  margin-bottom: 25px;
  margin-right: 20px;
}

.choice-input-explanation {
  font-weight: initial;
  margin-top: 6px;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/curafida-input/curafida-choice-input/curafida-choice-input.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,kBAAA;AACJ;;AACA;EACI,oBAAA;EACA,eAAA;AAEJ","sourcesContent":[".margins-checkbox {\n    margin-bottom: 25px;\n    margin-right: 20px;\n}\n.choice-input-explanation {\n    font-weight: initial;\n    margin-top: 6px;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
