export enum ErrorMessageType {
    required = 'required',
    minLength = 'minlength',
    maxLength = 'maxlength',
    pattern = 'pattern',
    same = 'same',
    min = 'min',
    max = 'max',
    minDate = 'minDate',
    maxDate = 'maxDate',
    format = 'format',
}

export class ErrorMessage {
    errorType: ErrorMessageType | string;
    formType: string;
    message: string;
}

export const errorMessagesConst: ErrorMessage[] = [
    { formType: 'defaultTextInput', errorType: ErrorMessageType.pattern, message: 'Diese Eingabe ist nicht gültig' },

    { formType: 'username', errorType: ErrorMessageType.required, message: 'Benutzername benötigt' },
    { formType: 'username', errorType: ErrorMessageType.minLength, message: 'Benutzername zu kurz' },

    { formType: 'firstname', errorType: ErrorMessageType.required, message: 'Vorname benötigt' },
    { formType: 'firstname', errorType: ErrorMessageType.minLength, message: 'Vorname zu kurz' },
    { formType: 'firstname', errorType: ErrorMessageType.pattern, message: 'Vorname ist ungültig' },

    { formType: 'lastname', errorType: ErrorMessageType.required, message: 'Nachname benötigt' },
    { formType: 'lastname', errorType: ErrorMessageType.minLength, message: 'Nachname zu kurz' },
    { formType: 'lastname', errorType: ErrorMessageType.pattern, message: 'Nachname ist ungültig' },

    { formType: 'email', errorType: ErrorMessageType.required, message: 'E-Mail Adresse benötigt' },
    { formType: 'email', errorType: ErrorMessageType.minLength, message: 'E-Mail Adresse zu kurz' },
    { formType: 'email', errorType: ErrorMessageType.pattern, message: 'E-Mail Adresse ist ungültig' },

    { formType: 'emailConfirmation', errorType: ErrorMessageType.same, message: 'E-Mail ist nicht identisch.' },

    { formType: 'gender', errorType: ErrorMessageType.required, message: 'Geschlecht benötigt' },
    { formType: 'gender', errorType: ErrorMessageType.minLength, message: 'Geschlecht zu kurz' },

    { formType: 'phone', errorType: ErrorMessageType.required, message: 'Telefonnummer benötigt' },
    { formType: 'phone', errorType: ErrorMessageType.minLength, message: 'Telefonnummer ist ungültig' },

    { formType: 'birthDate', errorType: ErrorMessageType.required, message: 'Geburtsdatum benötigt' },
    { formType: 'birthDate', errorType: ErrorMessageType.minLength, message: 'Geburtsdatum zu kurz' },
    { formType: 'birthDate', errorType: ErrorMessageType.format, message: 'Geburtsdatum zu kurz' },
    {
        formType: 'birthDate',
        errorType: ErrorMessageType.maxDate,
        message: 'Geburtsdatum muss in der Vergangenheit liegen',
    },

    { formType: 'title', errorType: ErrorMessageType.required, message: 'ERROR.TITLE_NEEDED' },
    { formType: 'title', errorType: ErrorMessageType.minLength, message: 'ERROR.TITLE_TOO_SHORT' },
    { formType: 'title', errorType: ErrorMessageType.maxLength, message: 'ERROR.TITLE_TOO_LONG' },

    { formType: 'description', errorType: ErrorMessageType.required, message: 'Beschreibung benötigt' },
    { formType: 'description', errorType: ErrorMessageType.minLength, message: 'Beschreibung zu kurz' },

    { formType: 'category', errorType: ErrorMessageType.required, message: 'Kategorie benötigt' },
    { formType: 'category', errorType: ErrorMessageType.minLength, message: 'Kategorie zu kurz' },

    { formType: 'duration', errorType: ErrorMessageType.required, message: 'Dauer benötigt' },
    { formType: 'duration', errorType: ErrorMessageType.minLength, message: 'Dauer zu kurz' },
    { formType: 'duration', errorType: ErrorMessageType.pattern, message: 'Dauer ungültig' },

    { formType: 'exerciseType', errorType: ErrorMessageType.required, message: 'Kategorie benötigt' },

    { formType: 'password1', errorType: ErrorMessageType.required, message: 'Passwort wird benötigt.' },
    { formType: 'password1', errorType: ErrorMessageType.minLength, message: 'Passwort ist zu kurz.' },
    {
        formType: 'password1',
        errorType: ErrorMessageType.pattern,
        message: 'Passwort entspricht nicht den Mindestanforderungen.',
    },

    { formType: 'password2', errorType: ErrorMessageType.required, message: 'Passwort wird benötigt.' },
    { formType: 'password2', errorType: ErrorMessageType.minLength, message: 'Passwort zu kurz.' },
    { formType: 'password2', errorType: ErrorMessageType.same, message: 'Passwort ist nicht identisch.' },
    {
        formType: 'password2',
        errorType: ErrorMessageType.pattern,
        message: 'Passwort entspricht nicht den Mindestanforderungen.',
    },

    { formType: 'repetitions', errorType: ErrorMessageType.required, message: 'Wiederholungen benötigt' },
    { formType: 'repetitions', errorType: ErrorMessageType.minLength, message: 'Wiederholungen zu kurz' },
    { formType: 'repetitions', errorType: ErrorMessageType.pattern, message: 'Wiederholungen ungültig' },

    { formType: 'delayTime', errorType: ErrorMessageType.required, message: 'Frequenz benötigt' },
    { formType: 'delayTime', errorType: ErrorMessageType.minLength, message: 'Frequenz zu kurz' },
    { formType: 'delayTime', errorType: ErrorMessageType.pattern, message: 'Frequenz ungültig' },

    { formType: 'firstName', errorType: ErrorMessageType.required, message: 'Vorname benötigt' },
    { formType: 'firstName', errorType: ErrorMessageType.minLength, message: 'Vorname zu kurz' },
    { formType: 'firstName', errorType: ErrorMessageType.pattern, message: 'Vorname ist ungültig' },

    { formType: 'lastName', errorType: ErrorMessageType.required, message: 'Nachname benötigt' },
    { formType: 'lastName', errorType: ErrorMessageType.minLength, message: 'Nachname zu kurz' },
    { formType: 'lastName', errorType: ErrorMessageType.pattern, message: 'Nachname ist ungültig' },

    { formType: 'patientTaskTemplate', errorType: ErrorMessageType.required, message: 'Aufgabenvorlage benötigt' },

    { formType: 'taskType', errorType: ErrorMessageType.required, message: 'Aufgabentyp benötigt' },

    { formType: 'responsibleRole', errorType: ErrorMessageType.required, message: 'Rollenzuweisung benötigt' },

    { formType: 'responsible', errorType: ErrorMessageType.required, message: 'Zuständige Person benötigt' },

    { formType: 'tradeName', errorType: ErrorMessageType.required, message: 'Handelsname benötigt' },

    { formType: 'strength', errorType: ErrorMessageType.required, message: 'Stärke benötigt' },

    { formType: 'dosingRegimen', errorType: ErrorMessageType.required, message: 'Dosierschema benötigt' },

    { formType: 'text', errorType: ErrorMessageType.required, message: 'Text benötigt' },

    { formType: 'bodyWeight', errorType: ErrorMessageType.pattern, message: 'Körpergewicht ungültig' },

    { formType: 'height', errorType: ErrorMessageType.pattern, message: 'Körpergröße ungültig' },

    { formType: 'temperature', errorType: ErrorMessageType.pattern, message: 'Temperatur ungültig' },

    { formType: 'pulse', errorType: ErrorMessageType.pattern, message: 'Puls ungültig' },

    { formType: 'systolic', errorType: ErrorMessageType.pattern, message: 'Systolisch ungültig' },

    { formType: 'diastolic', errorType: ErrorMessageType.pattern, message: 'Diastolisch ungültig' },

    { formType: 'spo2', errorType: ErrorMessageType.pattern, message: 'Sauerstoffsättigung ungültig' },

    { formType: 'healthInsurer', errorType: ErrorMessageType.required, message: 'Krankenversicherung benötigt' },

    { formType: 'healthInsurerNumber', errorType: ErrorMessageType.required, message: 'Versichertennummer benötigt' },

    { formType: 'name', errorType: ErrorMessageType.required, message: 'Name benötigt' },
    {
        formType: 'name',
        errorType: ErrorMessageType.minLength,
        message: 'Name zu kurz, es muss mindestens 5 Zeichen lang sein',
    },
    {
        formType: 'name',
        errorType: ErrorMessageType.pattern,
        message: 'Name ist ungültig.',
    },

    { formType: 'shortName', errorType: ErrorMessageType.required, message: 'Kürzel benötigt' },
    {
        formType: 'shortName',
        errorType: ErrorMessageType.minLength,
        message: 'Kürzel zu kurz, es muss mindestens 2 Zeichen lang sein',
    },

    { formType: 'publishStatus', errorType: ErrorMessageType.required, message: 'Bearbeitungsstatus benötigt' },

    { formType: 'formType', errorType: ErrorMessageType.required, message: 'Preis benötigt' },
    { formType: 'formType', errorType: ErrorMessageType.pattern, message: 'Preis ungültig' },
    { formType: 'formType', errorType: ErrorMessageType.max, message: 'Preis muss zwischen 0 und 999,99 liegen' },

    { formType: 'currency', errorType: ErrorMessageType.required, message: 'Währung benötigt' },

    { formType: 'vat', errorType: ErrorMessageType.required, message: 'MwSt benötigt' },
    { formType: 'vat', errorType: ErrorMessageType.min, message: 'MwSt muss zwischen 0 und 100 Prozent liegen' },
    { formType: 'vat', errorType: ErrorMessageType.max, message: 'MwSt muss zwischen 0 und 100 Prozent liegen' },
    { formType: 'vat', errorType: ErrorMessageType.pattern, message: 'MwSt muss eine Ganzzahl sein' },

    {
        formType: 'summary',
        errorType: ErrorMessageType.maxLength,
        message: 'Zusammenfassung darf nicht länger als 255 Zeichen sein',
    },

    {
        formType: 'seatsMax',
        errorType: ErrorMessageType.min,
        message: 'Maximale Teilnehmerzahl muss mindestens 0 betragen',
    },
    {
        formType: 'seatsMax',
        errorType: ErrorMessageType.pattern,
        message: 'Maximale Teilnehmerzahl muss eine Ganzzahl sein',
    },

    { formType: 'message', errorType: ErrorMessageType.required, message: 'Text benötigt' },
    { formType: 'message', errorType: ErrorMessageType.minLength, message: 'Text zu kurz' },

    { formType: 'price', errorType: ErrorMessageType.min, message: 'Preis unter 1,00 € und über 999,99 € ungültig' },
    { formType: 'price', errorType: ErrorMessageType.max, message: 'Preis unter 1,00 € und über 999,99 € ungültig' },

    {
        formType: 'pseudonym',
        errorType: ErrorMessageType.minLength,
        message: 'Pseudonym zu kurz, es muss mindestens 3 Zeichen lang sein',
    },

    { formType: 'contactInfo', errorType: ErrorMessageType.required, message: 'Kontaktinformation benötigt' },
    { formType: 'clinicalNotes', errorType: ErrorMessageType.required, message: 'Klinische Daten benötigt' },
    { formType: 'orderSubject', errorType: ErrorMessageType.required, message: 'Fragestellung benötigt' },
    {
        formType: 'calendarStartTime',
        errorType: 'appointmentOutOfInterval',
        message: 'Termin ist außerhalb der Bearbeitungszeit',
    },
    {
        formType: 'calendarEndTime',
        errorType: 'appointmentOutOfInterval',
        message: 'Termin ist außerhalb der Bearbeitungszeit',
    },
    {
        formType: 'exerciseSessionDuration',
        errorType: ErrorMessageType.min,
        message: 'APPOINTMENT_DURATION_MESSAGE',
    },
    {
        formType: 'exerciseSessionDuration',
        errorType: ErrorMessageType.required,
        message: 'Es muss eine Dauer angegeben werden',
    },

    { formType: 'titleAnnouncement', errorType: ErrorMessageType.required, message: 'ANNOUNCEMENT.ERROR.TITLE_NEEDED' },
    {
        formType: 'titleAnnouncement',
        errorType: ErrorMessageType.minLength,
        message: 'ANNOUNCEMENT.ERROR.TITLE_TOO_SHORT',
    },
    { formType: 'textBody', errorType: ErrorMessageType.required, message: 'ANNOUNCEMENT.ERROR.BODY_NEEDED' },
    { formType: 'textBody', errorType: ErrorMessageType.minLength, message: 'ANNOUNCEMENT.ERROR.BODY_TOO_SHORT' },

    {
        formType: 'titleTrainingProgression',
        errorType: ErrorMessageType.maxLength,
        message: 'TRAINING.PROGRESSION.TEMPLATE.ERROR.NAME_TOO_LONG',
    },
    {
        formType: 'titleTrainingProgression',
        errorType: ErrorMessageType.required,
        message: 'ERROR.TITLE_NEEDED',
    },
    {
        formType: 'descriptionTrainingProgression',
        errorType: ErrorMessageType.maxLength,
        message: 'TRAINING.PROGRESSION.TEMPLATE.ERROR.DESCRIPTION_TOO_LONG',
    },
    {
        formType: 'descriptionTrainingProgression',
        errorType: ErrorMessageType.required,
        message: 'ERROR.TITLE_NEEDED',
    },

    {
        formType: 'titleExerciseSeries',
        errorType: ErrorMessageType.maxLength,
        message: 'TRAINING.PROGRESSION.TEMPLATE.ERROR.NAME_TOO_LONG',
    },
    {
        formType: 'titleExerciseSeries',
        errorType: ErrorMessageType.required,
        message: 'ERROR.TITLE_NEEDED',
    },
    {
        formType: 'descriptionExerciseSeries',
        errorType: ErrorMessageType.maxLength,
        message: 'TRAINING.PROGRESSION.TEMPLATE.ERROR.DESCRIPTION_TOO_LONG',
    },
    {
        formType: 'descriptionExerciseSeries',
        errorType: ErrorMessageType.required,
        message: 'ERROR.TITLE_NEEDED',
    },

    {
        formType: 'seriesValue',
        errorType: ErrorMessageType.pattern,
        message: 'TRAINING.PROGRESSION.STEP.ERROR.SERIES_TOO_BIG',
    },
    {
        formType: 'durationValue',
        errorType: ErrorMessageType.pattern,
        message: 'TRAINING.PROGRESSION.STEP.ERROR.DURATION_TOO_BIG',
    },
    {
        formType: 'intensityValue',
        errorType: ErrorMessageType.pattern,
        message: 'TRAINING.PROGRESSION.STEP.ERROR.INTENSITY_TOO_BIG',
    },
    {
        formType: 'stressValue',
        errorType: ErrorMessageType.pattern,
        message: 'TRAINING.PROGRESSION.STEP.ERROR.STRESS_TOO_BIG',
    },

    /* Date picker specific error types and messages, defined in DayjsFormatValidator */
    { formType: 'picker', errorType: 'invalidDateFormat', message: 'ERROR.INVALID_DATE_FORMAT' },
    { formType: 'picker', errorType: 'invalidMaxDate', message: 'ERROR.INVALID_MAX_DATE_RANGE' },
    { formType: 'picker', errorType: 'invalidMinDate', message: 'ERROR.INVALID_MIN_DATE_RANGE' },

    /** Error message for UserCustomProperty text inputs with "validation" enabled */
    { formType: 'defaultTextInput', errorType: 'repeatValidation', message: 'REPEAT_VALIDATION_ERROR' },

    { formType: 'accountType', errorType: ErrorMessageType.required, message: 'ACCOUNT_TYPE.REQUIRED' },

    { formType: 'questionnaire', errorType: 'invalidJSONFormat', message: 'ERROR.INVALID_JSON_FORMAT' },
    {
        formType: 'questionnaire',
        errorType: ErrorMessageType.required,
        message: 'TASK.QUESTIONNAIRE.TEMPLATE.REQUIRED_ERROR',
    },
];
