import { Component, Input, OnInit, Optional, SecurityContext, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CurafidaInputComponent } from '../curafida-input';
import { TranslateService } from '@ngx-translate/core';
import { InputCustomEvent } from '@ionic/core';
import { DomSanitizer } from '@angular/platform-browser';

export enum InputMode {
    DECIMAL = 'decimal',
    NUMERIC = 'numeric',
    EMAIL = 'email',
    NONE = 'none',
    TEXT = 'text',
    URL = 'url',
}

@Component({
    selector: 'curafida-text-input',
    templateUrl: './curafida-text-input.component.html',
    styleUrls: ['./curafida-text-input.component.scss'],
})
export class CurafidaTextInputComponent extends CurafidaInputComponent implements OnInit {
    @Input()
    unit: string;

    private _explanation: string;

    get explanation(): string {
        return this._explanation;
    }

    @Input()
    set explanation(explanation: string) {
        this._explanation = this.sanitizer.sanitize(SecurityContext.HTML, explanation);
    }

    @Input()
    inputType = 'text';

    // A hint to the app for which keyboard to display
    @Input()
    inputMode?: InputMode;

    @Input()
    labelIcon = '';

    @Input()
    labelIconColor = '';

    @Input()
    borderColorPrimary = false;

    @Input()
    isMobile: boolean;

    @Input() floatingLabelMobile = true;
    @Input() maxlength: number;

    constructor(
        @Self()
        @Optional()
        public ngControl: NgControl,
        public translateService: TranslateService,
        public sanitizer: DomSanitizer,
    ) {
        super(ngControl, translateService, sanitizer);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    changeValue(value: InputCustomEvent) {
        const newInputValue = value?.detail?.value;
        if (!this.formGroup || !this.formControlName) {
            // Warning: no form set
            console.error('Error: no form was set');
            return;
        }
        const formControl = this.formGroup?.controls[this.formControlName];
        if (formControl.value !== newInputValue) {
            formControl.setValue(value?.detail?.value);
            formControl.markAsDirty();
        }
        value.stopPropagation();
    }
}
