import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../../api/api.service';
import { PaginatedResponse, SortBy, SortOrder } from '../../common/entities/paginated-response';
import { FileContentService } from '../../common/services/content/file-content.service';
import { LoadingService } from '../../common/services/loading/loading.service';
import { Logger, LoggingService } from '../../logging/logging.service';
import { MyMedaxFillLink } from '../../my-medax/entities/my-medax-fill-link';
import { MyMedaxRedirection } from '../../my-medax/my-medax.redirection';
import {
    Consultation,
    ConsultationStateEnum,
    CreateConsultationDto,
    UpdateConsultationRequestDto,
} from '../entities/consultation';
import { ConsultationContent } from '../entities/consultation-content';
import { ConsultationQuestionnaire } from '../entities/consultation-questionnaire';
import { Observable } from 'rxjs';
import { ConsultationEvent } from '../entities/consultation-event';

@Injectable({
    providedIn: 'root',
})
export class ConsultationService {
    protected readonly log: Logger;

    constructor(
        private loggingService: LoggingService,
        private http: HttpClient,
        private loadingService: LoadingService,
        private fileContentService: FileContentService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async getConsultations(
        patientUsername?: string,
        limit?: number,
        offset?: number,
        sortBy?: SortBy,
        sortOrder?: SortOrder,
        consultationStates?: ConsultationStateEnum[],
        withChat?: boolean,
        withEvents?: boolean,
        withGroups?: boolean,
        withUsers?: boolean,
        withContents?: boolean,
    ): Promise<PaginatedResponse<Consultation[]>> {
        const url = new URL(`${ApiService.url}consultations`);
        if (patientUsername) url.searchParams.set('patientUsername', patientUsername.toString());
        if (limit) url.searchParams.set('limit', limit.toString());
        if (offset) url.searchParams.set('offset', offset.toString());
        if (sortBy) url.searchParams.set('sortBy', sortBy.toString());
        if (sortOrder) url.searchParams.set('sortOrder', sortOrder.toString());
        if (withChat) url.searchParams.set('withChat', withChat.toString());
        if (withEvents) url.searchParams.set('withEvents', withEvents.toString());
        if (withGroups) url.searchParams.set('withGroups', withGroups.toString());
        if (withUsers) url.searchParams.set('withUsers', withUsers.toString());
        if (withContents) url.searchParams.set('withContents', withContents.toString());
        if (consultationStates) url.searchParams.set('consultationStates', consultationStates.toString());
        return await this.http.get<PaginatedResponse<Consultation[]>>(url.toString(), ApiService.options).toPromise();
    }

    async getConsultation(
        consultationUuid: string,
        withChat?: boolean,
        withEvents?: boolean,
        withGroups?: boolean,
        withUsers?: boolean,
        withContents?: boolean,
        markEventsAsRead?: boolean,
    ): Promise<Consultation> {
        const url = new URL(`${ApiService.url}consultations/${consultationUuid}`);
        if (withChat) url.searchParams.set('withChat', withChat.toString());
        if (withEvents) url.searchParams.set('withEvents', withEvents.toString());
        if (withGroups) url.searchParams.set('withGroups', withGroups.toString());
        if (withUsers) url.searchParams.set('withUsers', withUsers.toString());
        if (withContents) url.searchParams.set('withContents', withContents.toString());
        if (markEventsAsRead) url.searchParams.set('markEventsAsRead', markEventsAsRead.toString());
        return await this.http.get<Consultation>(url.toString(), ApiService.options).toPromise();
    }

    async createConsultation(requester: string, consultant: string, patient: string, consultationSubject: string) {
        this.loadingService.startLoadingModal();
        const newConsultation = new CreateConsultationDto(requester, consultant, patient, consultationSubject);
        return await this.http
            .post<Consultation>(`${ApiService.url}consultations`, newConsultation, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async updateConsultationRequest(consultationUuid: string, payload: UpdateConsultationRequestDto) {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}consultations/${consultationUuid}/updateRequest`);
        return await this.http
            .post<Consultation>(url.toString(), payload, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async sendConsultationRequest(consultationUuid: string) {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}consultations/${consultationUuid}/sendRequest`);
        return await this.http
            .post<Consultation>(url.toString(), null, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async acceptConsultationRequest(
        consultationUuid: string,
        providedConsultantServices?: string[],
        justifyingIndicationNotes?: string,
    ): Promise<Consultation> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}consultations/${consultationUuid}/accept`);
        const body = {
            providedConsultantServices: providedConsultantServices ? providedConsultantServices : [],
            justifyingIndicationGiven: false,
            justifyingIndicationNotes: justifyingIndicationNotes ? justifyingIndicationNotes : null,
        };
        return await this.http
            .post<Consultation>(url.toString(), body, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async closeConsultation(consultationUuid: string): Promise<Consultation> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}consultations/${consultationUuid}/close`);
        return await this.http
            .post<Consultation>(url.toString(), null, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async confirmConsultationClosure(consultationUuid: string): Promise<Consultation> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}consultations/${consultationUuid}/confirmClosure`);
        return await this.http
            .post<Consultation>(url.toString(), null, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async reopenConsultation(consultationUuid: string): Promise<Consultation> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}consultations/${consultationUuid}/reopen`);
        return await this.http
            .post<Consultation>(url.toString(), null, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async cancelConsultation(consultationUuid: string): Promise<Consultation> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}consultations/${consultationUuid}/cancel`);
        return await this.http
            .post<Consultation>(url.toString(), null, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async deleteConsultation(consultationUuid: string): Promise<Consultation> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}consultations/${consultationUuid}/delete`);
        return await this.http
            .post<Consultation>(url.toString(), null, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async revokeConsultation(consultationUuid: string): Promise<Consultation> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}consultations/${consultationUuid}/revoke`);
        return await this.http
            .post<Consultation>(url.toString(), null, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async declineConsultationRequest(consultationUuid: string): Promise<Consultation> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}consultations/${consultationUuid}/decline`);
        return await this.http
            .post<Consultation>(url.toString(), null, ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    // Deprecated, use getConsultation() and then consultation.contents attribute instead
    async getConsultationContent(
        consultationUuid: string,
        isFormContent = false,
        parentsOnly = true,
    ): Promise<ConsultationContent[]> {
        const url = new URL(`${ApiService.url}consultations/${consultationUuid}/files`);
        if (parentsOnly) url.searchParams.set('parentsOnly', parentsOnly.toString());
        const files = await this.http.get<ConsultationContent[]>(url.toString(), ApiService.options).toPromise();
        return files.filter((a) => a.name !== 'index.json' && a.isFormContent === isFormContent);
    }

    async uploadFile(consultationUuid: string, asset, parentId?: string): Promise<ConsultationContent> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}consultations/${consultationUuid}/files`);
        if (parentId) url.searchParams.set('offset', parentId.toString());
        const headers = new HttpHeaders().append('authorization', ApiService.options.headers.get('authorization'));
        headers.append('content-type', 'multipart/form-data');
        const options = { headers, withCredentials: true };
        return await this.http
            .post<ConsultationContent>(url.toString(), asset, options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async deleteFile(consultationUuid: string, consultationContentId: string): Promise<void> {
        this.loadingService.startLoadingModal();
        const url = new URL(`${ApiService.url}consultations/${consultationUuid}/files/${consultationContentId}`);
        await this.http
            .delete<void>(url.toString(), ApiService.options)
            .toPromise()
            .finally(() => this.loadingService.stopLoadingModal());
    }

    async getFileDownloadUrl(consultationUuid: string, consultationContent: ConsultationContent) {
        const url = new URL(
            `${ApiService.url}consultations/${consultationUuid}/contents/${consultationContent.uuid}/download`,
        );
        await this.fileContentService.openObjectURLinNewWindow(url.toString());
    }

    async downloadFile(consultationUuid: string, consultationContent: ConsultationContent) {
        await this.fileContentService.downloadObjectURLinBrowser(
            `consultations/${consultationUuid}/contents/${consultationContent.uuid}/download`,
        );
    }

    async getConsultationQuestionnaires(consultationUuid: string): Promise<ConsultationQuestionnaire[]> {
        const url = new URL(`${ApiService.url}consultations/myMedax/${consultationUuid}/questionnaires`);
        return await this.http.get<ConsultationQuestionnaire[]>(url.toString(), ApiService.options).toPromise();
    }

    async getFillLink(
        consultationUuid: string,
        consultationQuestionnaireUuid: string,
        myMedaxRedirection: MyMedaxRedirection,
        prefill?: boolean,
        prefillSubmissions?: string[],
        submissionVersioningUuid?: string,
    ) {
        const url = new URL(
            `${ApiService.url}consultations/myMedax/${consultationUuid}` +
                `/questionnaires/${consultationQuestionnaireUuid}/fillLink`,
        );
        if (myMedaxRedirection.isActive) {
            url.searchParams.append('redirectAfterFillUri', encodeURIComponent(myMedaxRedirection.url().toString()));
        }
        if (prefill) url.searchParams.set('prefill', prefill.toString());
        if (prefillSubmissions) url.searchParams.set('prefillSubmissions', prefillSubmissions.toString());
        if (submissionVersioningUuid) {
            url.searchParams.set('submissionVersioningUuid', submissionVersioningUuid.toString());
        }
        return await this.http.get<MyMedaxFillLink>(url.toString(), ApiService.options).toPromise();
    }

    createConsultationPdfReport(consultationUuid: string): Observable<ConsultationEvent> {
        return this.http.post<ConsultationEvent>(`consultations/${consultationUuid}/createPdfReport`, null);
    }
}
