import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    SecurityContext,
    Self,
    SimpleChanges,
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    NgControl,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { InputCustomEvent } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { compareAsc, compareDesc, endOfDay, isAfter, isBefore, startOfDay } from 'date-fns';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ECalendarValue, IDatePickerDirectiveConfig } from 'ng2-date-picker';
import { CalendarMode } from 'ng2-date-picker/lib/common/types/calendar-mode';
import { Subscription } from 'rxjs';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { CurafidaInputComponent } from '../curafida-input';
import { DomSanitizer } from '@angular/platform-browser';

const DEF_CONF: IDatePickerDirectiveConfig = {
    firstDayOfWeek: 'mo',
    disableKeypress: false,
    closeOnSelect: false,
    closeOnSelectDelay: 100,
    onOpenDelay: 100,
    showNearMonthDays: true,
    showWeekNumbers: false,
    enableMonthSelector: true,
    showGoToCurrent: true,
    dayBtnFormat: 'DD',
    monthBtnFormat: 'MMM',
    hours12Format: 'hh',
    hours24Format: 'HH',
    meridiemFormat: 'A',
    minutesFormat: 'mm',
    minutesInterval: 5,
    showSeconds: false,
    showTwentyFourHours: true,
    timeSeparator: ':',
    hideInputContainer: false,
    returnedValueType: ECalendarValue.String,
    unSelectOnClick: false,
    numOfMonthRows: 4,
};

export class DayjsFormatValidator {
    constructor() {
        dayjs.locale('de');
        dayjs.extend(customParseFormat);
    }

    /* Validate the format of the date.
     * When clicking the popover date picker this format is already applied to the value.
     * This validator ensures the same format when typing the date in the text input field.
     * validDate, validate, get it? :D
     */
    static validDateFormat(controlName: string, format: string): ValidatorFn {
        return (group: AbstractControl): ValidationErrors | null => {
            const control = group.get(controlName);
            if (!control || !format) {
                throw new Error(
                    `${this.constructor.name}: FormControl with name ${controlName} not found or format not specified`,
                );
            }
            /* If the FormControl holds no value or an empty value, it should still be valid.
             * If the FormControl is required, that should be achieved with a different validator.
             */
            if (!control.value) {
                return null;
            }
            const error = dayjs(control.value, format, true).isValid() ? null : { invalidDateFormat: true };
            control.setErrors(error);
            return error;
        };
    }

    /* Validate the range of the date against a max and min value.
     * The popover date picker already ensures this by not allowing to click on dates outside the valid range.
     * This validator applies the same limitation to typing the date in the text input field.
     * validDate, validate, get it? :D
     */
    static validDateRange(
        controlName: string,
        format: string,
        max?: string,
        min?: string,
        fullDay = true,
        disabledPastDays = false,
    ): ValidatorFn {
        return (group: AbstractControl): ValidationErrors | null => {
            const control = group.get(controlName);
            if (!control) {
                throw new Error(`${this.constructor.name}: form control with name "${controlName}" not found`);
            }
            /* If the FormControl holds no value or an empty value, it should still be valid.
             * If the FormControl is required, that should be achieved with a different validator.
             */
            if (!control.value) {
                return null;
            }
            /* If the input text is not a valid date, return null.
             * Checking date formatting should be achieved with a different validator.
             */
            if (!dayjs(control.value, format, true).isValid()) {
                return null;
            }
            /* If there is no range then it is valid, return null */
            if (!min && !max) {
                return null;
            }
            /* Because max and min are ISO strings they are timestamps with millisecond precision.
             * To allow for the full day of the starting and/or ending date of the range to be selected
             * they need to be adjusted as follows:
             */
            if (fullDay) {
                max = max ? endOfDay(new Date(max)).toISOString() : max;
                min = min ? startOfDay(new Date(min)).toISOString() : min;
            }
            const errors = { invalidMaxDate: false, invalidMinDate: false };
            if (max && isAfter(dayjs(control.value, format).toDate(), new Date(max))) {
                errors.invalidMaxDate = true;
            }
            if (min && isBefore(dayjs(control.value, format).toDate(), new Date(min))) {
                if (!(isBefore(dayjs(control.value, format).toDate(), new Date()) && disabledPastDays)) {
                    errors.invalidMinDate = true;
                }
            }
            /* Remove falsy properties from the errors object */
            Object.keys(errors).forEach((key) => !errors[key] && delete errors[key]);
            /* If the errors object does not contain keys "{}" set result to null */
            const result = Object.keys(errors).length < 1 ? null : errors;
            control.setErrors(result);
            return result;
        };
    }
}

@Component({
    selector: 'curafida-date-input',
    templateUrl: './curafida-date-input.component.html',
    styleUrls: ['./curafida-date-input.component.scss'],
})
export class CurafidaDateInputComponent extends CurafidaInputComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    datePickerMode: CalendarMode = 'day';
    @Input()
    disabledPastDays = false;
    @Input()
    disabledFutureDays = false;
    @Input()
    borderColorPrimary = false;
    @Input()
    isEditEnabled = true;
    @Output()
    validDateString: EventEmitter<string> = new EventEmitter<string>();
    @Input()
    minuteStep?: number = 5;
    @Input()
    minDate: string;
    @Input()
    maxDate: string;
    @Input()
    roundedMinutes? = true;
    @Input()
    renderHtml = false;

    private _explanation: string;
    @Input() set explanation(explanation: string) {
        this._explanation = this.sanitizer.sanitize(SecurityContext.HTML, explanation);
    }
    get explanation(): string {
        return this._explanation;
    }

    config: IDatePickerDirectiveConfig;
    displayDate: dayjs.Dayjs;
    datePickerFormGroup: FormGroup;
    private dateFormat: string = 'DD.MM.YYYY';
    private min: string;
    private max: string;
    private parentValueSub: Subscription;
    private parentStatusSub: Subscription;
    private configProps: string[];
    private readonly log: Logger;

    constructor(
        @Self()
        @Optional()
        public ngControl: NgControl,
        private loggingService: LoggingService,
        public translateService: TranslateService,
        public sanitizer: DomSanitizer,
    ) {
        super(ngControl, translateService, sanitizer);
        this.log = this.loggingService.getLogger(this.constructor.name);
        dayjs.locale('de');
        dayjs.extend(customParseFormat);
        this.configProps = [
            'datePickerMode',
            'disabledPastDays',
            'disabledFutureDays',
            'minDate',
            'maxDate',
            'minuteStep',
            'roundedMinutes',
        ];
    }

    async ngOnInit(): Promise<void> {
        await super.ngOnInit();
        /* Error messages are set in CurafidaInputComponent based on the formControlName of the parent component.
         * The date picker has its custom validators, so add the possible error messages here
         */
        this.formErrors.push(...this.errorMessages.filter((i) => i.formType === 'picker'));
        this.initDatePickerConfig();
        this.datePickerFormGroup = new FormGroup(
            {
                picker: new FormControl<string>({
                    value: this.setValueOnInputComponent(this.formGroup.controls[this.formControlName].value, true),
                    disabled: this.formGroup.controls[this.formControlName].disabled,
                }),
            },
            [
                DayjsFormatValidator.validDateFormat('picker', this.dateFormat),
                DayjsFormatValidator.validDateRange(
                    'picker',
                    this.dateFormat,
                    this.max,
                    this.min,
                    this.datePickerMode === 'day',
                    this.disabledPastDays,
                ),
            ],
        );
        if (this.isRequired) {
            this.datePickerFormGroup.controls['picker'].addValidators(Validators.required);
        }
        /* Propagate the status of the date picker FormControl to the original parent component FormControl.
         * This is relevant for validation purposes as only the date picker control implements the DayjsFormatValidator.
         */
        this.datePickerFormGroup.controls['picker'].statusChanges.subscribe((status) => {
            switch (status) {
                case 'VALID':
                    this.formGroup.controls[this.formControlName].setErrors(null);
                    break;
                case 'INVALID':
                    const errors = this.datePickerFormGroup.controls['picker'].errors;
                    this.formGroup.controls[this.formControlName].setErrors(errors);
                    break;
                default:
                    break;
            }
        });
        /* If for any reason the value in the parent FormControl changes without being changed in the child component
         * (for example programmatically with setValue or patchValue) ensure that the child component also reflects
         * this change accordingly.
         */
        this.parentValueSub = this.formGroup.controls[this.formControlName].valueChanges.subscribe((value) => {
            const formattedDate = this.setValueOnInputComponent(value);
            if (this.datePickerFormGroup.controls['picker'].value !== formattedDate) {
                this.datePickerFormGroup.controls['picker'].patchValue(formattedDate);
            }
        });

        this.parentStatusSub = this.formGroup.controls[this.formControlName].statusChanges.subscribe((status) => {
            switch (status) {
                case 'DISABLED':
                    if (!this.datePickerFormGroup.controls['picker'].disabled) {
                        this.datePickerFormGroup.controls['picker'].disable();
                    }
                    break;
                default:
                    if (this.datePickerFormGroup.controls['picker'].disabled) {
                        this.datePickerFormGroup.controls['picker'].enable();
                    }
                    break;
            }
        });
    }

    /* If any of the inputs that affect the configuration of the date picker change, ensure these changes are applied */
    ngOnChanges(changes: SimpleChanges): void {
        /* Array of the names of the changed properties */
        const changedProps = Object.keys(changes);
        /* Compare it with a static list of properties that affect the configuration/validation of the date picker */
        const changedConfigs = changedProps.filter((value) => {
            if (this.configProps.includes(value)) {
                /* ngOnChanges runs on initialization too, so ignore if it is the first change */
                return !changes[value].isFirstChange();
            }
        });
        /* If none of these properties has been changed, return without going further */
        if (changedConfigs.length < 1) {
            return;
        }
        this.initDatePickerConfig();
        /* setValidators() removes the existing validators and replaces them with the new ones */

        this.datePickerFormGroup.setValidators([
            DayjsFormatValidator.validDateFormat('picker', this.dateFormat),
            DayjsFormatValidator.validDateRange(
                'picker',
                this.dateFormat,
                this.max,
                this.min,
                this.datePickerMode === 'day',
                this.disabledPastDays,
            ),
        ]);
        if (this.isRequired) {
            this.datePickerFormGroup.controls['picker'].addValidators(Validators.required);
        }
    }

    /* Formats and sets the value on the FormControl of the parent component.
     * Also emits the output event to the parent component.
     */
    setValueOnParentComponent(event: InputCustomEvent): void {
        const value = event.detail.value;
        /* Only emit event and patch the value on the parent component if the value is also valid.
         * The errors attribute of the FormControl should be null for the value to be valid.
         */
        if (!this.datePickerFormGroup.controls['picker'].errors) {
            const isoStringValue = value
                ? this.roundMinutesIfConfigured(dayjs(value, this.dateFormat).toISOString())
                : '';
            this.formGroup.controls[this.formControlName].patchValue(isoStringValue);
            this.formGroup.controls[this.formControlName].markAsDirty();
            this.validDateString.emit(isoStringValue);
        }
    }

    ngOnDestroy(): void {
        this.parentValueSub?.unsubscribe();
        this.parentStatusSub?.unsubscribe();
    }

    initDatePickerConfig(): void {
        this.min = undefined;
        this.max = undefined;
        if (this.disabledFutureDays) {
            this.max = this.datePickerMode === 'day' ? endOfDay(new Date()).toISOString() : new Date().toISOString();
        }
        if (this.disabledPastDays) {
            this.min = this.datePickerMode === 'day' ? startOfDay(new Date()).toISOString() : new Date().toISOString();
        }
        if (this.maxDate) {
            this.max = new Date(this.maxDate).toISOString();
        }
        if (this.minDate) {
            this.min = new Date(this.minDate).toISOString();
        }
        if (this.datePickerMode === 'day') {
            this.dateFormat = 'DD.MM.YYYY';
        }
        if (this.datePickerMode === 'daytime') {
            this.dateFormat = 'DD.MM.YYYY H:mm';
        }
        this.config = {
            ...DEF_CONF,
            minutesInterval: this.roundedMinutes ? this.minuteStep : 1,
            format: this.dateFormat,
            max: this.max ? dayjs(this.max) : null,
            min: this.min ? dayjs(this.min) : null,
        };
    }

    /* Processes the input value coming from the parent component and returns the new value to be used. This includes:
     * 1. Artificial type safety, ensuring the value in the FormControl is always a string.
     * 2. Minutes rounding if configured.
     * 3. Setting the date to display when opening the date picker popover.
     * 4. Formatting the value to the desired Dayjs string format.
     * 5. Updating the parent FormControl with any adjustments, if necessary.
     */
    private setValueOnInputComponent(inputValue: string, ignoreRounding: boolean = false): string {
        /* Artificial type safety ot ensure the received input is read as a string even if null or undefined. */
        const dateStringFromParent = inputValue ?? '';
        const initialDate = ignoreRounding ? dateStringFromParent : this.roundMinutesIfConfigured(dateStringFromParent);
        /* Often times the input from the parent component is just "new Date()"
         * so it needs to be rounded and adjusted on the parent component as well, if rounding is configured
         */
        if (
            this.formGroup.controls[this.formControlName].enabled &&
            this.formGroup.controls[this.formControlName].value !== initialDate
        ) {
            this.formGroup.controls[this.formControlName].patchValue(initialDate);
        }
        /* The displayDate tells the date picker what date to open the popover on.
         * Normally this only needs to be set once as it is only relevant on the first time the popover is opened,
         * but since the value of the parent FormControl might change even without opening the popover
         * (for example programmatically) we need the displayDate to also update accordingly.
         * If the input is not empty open it on the input date, otherwise on today/now.
         * If the input is invalid (disabledPastDays / disabledFutureDates) it will be set so today/now
         */
        if (this.disabledPastDays && compareAsc(new Date(initialDate), startOfDay(new Date())) >= 0) {
            this.displayDate = initialDate
                ? dayjs(initialDate)
                : dayjs(this.roundMinutesIfConfigured(new Date().toISOString()));
        } else if (this.disabledFutureDays && compareDesc(new Date(initialDate), endOfDay(new Date())) >= 0) {
            this.displayDate = initialDate
                ? dayjs(initialDate)
                : dayjs(this.roundMinutesIfConfigured(new Date().toISOString()));
        }

        if (!initialDate) {
            return '';
        }
        return dayjs(initialDate).format(this.dateFormat);
    }

    private roundMinutesIfConfigured(input: string): string {
        if (!input) {
            return '';
        }
        if (!this.roundedMinutes) {
            return input;
        }
        /* roundToNearestMinutes is currently bugged and does not work properly,
         * see https://gitlab.ztm-badkissingen.de/curafida/development/ionic-common/-/issues/6680.
         * This custom method seems to work.
         */
        const millisecondsToRound = 1000 * 60 * this.minuteStep;
        return new Date(Math.ceil(new Date(input).getTime() / millisecondsToRound) * millisecondsToRound).toISOString();
        /* return roundToNearestMinutes(new Date(input), {
            nearestTo: this.minuteStep,
            roundingMethod: 'ceil',
        }).toISOString(); */
    }
}
