import { Component, Input, OnInit, Optional, SecurityContext, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CurafidaInputComponent } from '../curafida-input';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

export interface CurafidaSliderInput {
    min: number;
    max: number;
    step: number;
    unit?: string;
}

@Component({
    selector: 'curafida-slider-input',
    templateUrl: './curafida-slider-input.component.html',
    styleUrls: ['./curafida-slider-input.component.scss'],
})
export class CurafidaSliderInputComponent extends CurafidaInputComponent implements OnInit {
    @Input() explanation: string;
    @Input() sliderInput: CurafidaSliderInput;

    initValue: number;

    constructor(
        @Self()
        @Optional()
        public ngControl: NgControl,
        public translateService: TranslateService,
        public sanitizer: DomSanitizer,
    ) {
        super(ngControl, translateService, sanitizer);
    }

    ngOnInit() {
        super.ngOnInit();
        const value = this.formGroup.controls[this.formControlName].value;
        if (!isNaN(value)) {
            this.initValue = value;
        }
    }

    sanitizeHtml(html: string) {
        return this.sanitizer.sanitize(SecurityContext.HTML, html);
    }

    valueChanged(valueChange: { detail: { value: number } }) {
        const value = valueChange.detail.value;
        this.formGroup.patchValue({ [this.formControlName]: value });
    }
}
