// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sc-ion-input-md-h {
  --padding-start: 0 !important;
}

ul {
  -webkit-padding-start: 15px !important;
          padding-inline-start: 15px !important;
}

.label-padding-top {
  padding-top: calc(var(--padding-top) + 8px);
}

.explanation-margin-top {
  margin-top: 6px;
}

.init-font-weight {
  font-weight: initial;
}`, "",{"version":3,"sources":["webpack://./../../libs/common/components/curafida-input/curafida-text-area/curafida-text-area.component.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;AACJ;;AAEA;EACI,sCAAA;UAAA,qCAAA;AACJ;;AAEA;EACI,2CAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,oBAAA;AACJ","sourcesContent":[".sc-ion-input-md-h {\n    --padding-start: 0 !important;\n}\n\nul {\n    padding-inline-start: 15px !important;\n}\n\n.label-padding-top {\n    padding-top: calc(var(--padding-top) + 8px)\n}\n\n.explanation-margin-top {\n    margin-top: 6px;\n}\n\n.init-font-weight {\n    font-weight: initial;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
